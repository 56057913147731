<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')" />
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div v-if="!submitted" class="content_input">
          <p :class="`title title_${color}`">Esqueci minha senha</p>
          <form @submit.prevent="submit" @keyup.enter.prevent="submit">
            <v-input name="recuperarEmail" v-model="recuperarEmail" :error="errors.first('recuperarEmail')"
              label="E-mail" v-validate="'required|email'"></v-input>
            <v-button class="flex justify-center" :color="color" :loading="loading">Enviar</v-button>
          </form>
        </div>
        <div v-else class="content_confirm">
          <p :class="`title title_${color}`">Solicitação enviada com sucesso!</p>
          <p class="subtitle">Se o e-mail estiver correto, você deverá receber um link para redefinir sua senha. </p>
          <p class="subtitle">Verifique sua caixa de entrada! </p>
          <v-button class="flex justify-center" :color="color" @click="close" :loading="loading">Fechar</v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'

import ForgotPassword from '@/services/Commons/ForgotPassword'

export default {
  props: ['color', 'req', 'email'],
  components: { VInput, VButton },
  data() {
    return {
      submitted: false,
      loading: false,
      recuperarEmail: ''
    }
  },

  methods: {
    setEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        this.recuperarEmail = email;
    },
    close() {
      this.$emit('closeModal')
      this.submitted = false
      this.loading = false
      this.recuperarEmail = ''
      this.$validator.reset()
    },

    async submit() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            const grecaptcha = await this.$recaptcha('login')
            await ForgotPassword.forgotPassword({ email: this.recuperarEmail, grecaptcha }, this.req ? this.req : this.color === 'primary' ? 'tomador' : 'investidor')
          } catch (err) {
            console.error(err)
          } finally {
            this.loading = false
            this.submitted = true
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full bg-white z-50 flex flex-col justify-center rounded-lg pt-8
  @screen lg
    @apply w-2/3

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.content_input
  @apply flex flex-col w-full px-4
  @screen lg
    @apply px-8

.content_confirm
  @apply flex flex-col items-center px-2

.title
  @apply font-bold mb-3
  &_primary
    @apply text-primary
  &_secondary
    @apply text-secondary

.subtitle
  @apply text-sm opacity-75 text-center

</style>
