<template>
  <div>
    <transition name="fade">
      <forgot-password-modal ref="modalForgotPassword" v-show="forgotPassword" @closeModal="forgotPassword = false" />
    </transition>
    <div class="wrapper">
      <div class="image"></div>
      <div class="article">
        <div class="article_content">
          <p class="title">Seu <span class="text-primary">investimento,</span> com <span class="text-primary">retorno
              justo</span> e <span class="text-primary">impacto social</span></p>
          <p class="subtitle">Investimentos</p>
          <p class="text">A partir de R$ 500,00 e possibilidades de alocar em diferentes empresas</p>
          <p class="subtitle">Mitigação de Riscos</p>
          <p class="text">Modelo proprietário de crédito sofisticado</p>
          <p class="subtitle">Acesso facilitado</p>
          <p class="text">Sem burocracia e toda contratação online</p>
          <p class="subtitle">Recebimento mensal</p>
          <p class="text">Com amortização e juros</p>
        </div>
      </div>
      <div class="form_section container">
        <div class="form_content">
          <div v-if="false && this.$route.query.i === 'BLACKFRIDAY2021'" class="bf">PARA ATIVAR O CASHBACK DA CAMPANHA
            DE BLACKFRIDAY É PRECISO FAZER O LOGIN</div>

          <div class="logo" @click="$router.push('/')"></div>
          <div v-if="this.$route.query.bearerToken && !this.$route.query.user" class="form_title">Confirmando
            autenticação remota de administrador, aguarde...</div>
          <div v-if="this.$route.query.bearerToken && this.$route.query.user" class="form_title">Confirmando
            autenticação e redirecionando para o painel, aguarde...</div>

          <div v-if="!this.$route.query.bearerToken && this.$route.query.i !== 'BLACKFRIDAY2021' || true"
            class="form_title">Faça login e acompanhe seus investimentos</div>
          <form v-if="!this.$route.query.bearerToken" @submit.prevent="login" @keyup.enter="login"
            class="w-full flex flex-col">
            <v-input v-lowercase v-trim v-if="$route.query.administrador" name="adminEmail" v-model="form.adminEmail"
              :error="errors.first('adminEmail')" label="E-mail de Administrador"
              v-validate="'required|email'"></v-input>
            <v-input v-lowercase v-trim name="email" v-model="form.email" :error="errors.first('email')" label="E-mail"
              v-validate="'required|email'"></v-input>
            <v-input name="password" type="password" v-model="form.password" :error="errors.first('password')"
              label="Senha" v-validate="'required'"></v-input>
            <p class="pass_recovery" @click="$router.push({ path: '/investimento/solicitar' })">Ainda não tenho cadastro
            </p>
            <p class="pass_recovery" @click="showModalForgotPassord">Esqueci minha senha</p>

            <v-button color="secondary" class="self-end" :loading="loading">Entrar</v-button>
          </form>

          <div id="log" class="container" v-if="$route.query.debug"
            style="padding: 5px; max-width: 600px;; min-height: 200px; max-height: 200px; border: 1px solid #333;overflow-y: scroll;">

          </div>

          <p class="social_title" v-if="!$route.query.i">Entrar com as redes sociais</p>
          <div class="social" v-if="!$route.query.i">
            <div @click="facebookAuthenticate" class="social_facebook"></div>
            <div @click="googleAuthenticate" class="social_google_plus"></div>
          </div>
        </div>

        <small style="color: #333; font-size: 0.6em"> {{ new Date() }}</small>

        <div class="footer">
          Copyright © TuTu Digital. Todos os Direitos Reservados.
          Tutu Digital Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida
          Paulista, 1374 - Andar 11, São Paulo, SP. CEP 01310-100.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ForgotPasswordModal from '@/components/Modal/ForgotPasswordModal'
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'
import Login from '@/services/Landing/Login'
import LoginBearer from '@/services/Commons/LoginBearer'
import jwt_decode from "jwt-decode";

export default {
  components: { ForgotPasswordModal, VInput, VButton },
  data() {
    return {
      forgotPassword: false,
      loading: false,
      returnUrl: null,
      form: {
        adminEmail: '',
        email: '',
        password: ''
      }
    }
  },
  methods: {
    showModalForgotPassord() {
      this.forgotPassword = true;
      this.$refs.modalForgotPassword.setEmail(this.form.email)
    },
    async login() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          var response;
          try {
            const grecaptcha = await this.$recaptcha('login')
            console.info(`login: request login iniciada - ${this.form.email}`)
            response = (await Login.login({ ...this.form, grecaptcha, extraInfo: this.$route.query.i }));
            console.info(`login: request login finalizada - ${this.form.email}`)

            console.info(`login: verificando integridade do retorno - ${this.form.email}`)
            if (response == null || ((response.data == null || response.data.data == null) && !response.data.erros)) {
              this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro na requisição de login' })
              console.error(response);
            }
            console.log(response.data)
            console.info(`login: verificando roles - ${this.form.email}`)
            if (!response.data.data.roles.includes('investidor')) throw new Error('roleError')

            console.info(`login: fazendo logout - ${this.form.email}`)

            await this.$store.dispatch('setLogout')
            console.info(`login: fazendo login - ${this.form.email}`)
            await this.$store.dispatch('setLogin', response.data.data)

            console.info(`login: redirecionando para painel - ${this.form.email}`)

            this.$router.push('/investidor/painel')
            console.info(`login: redirecionado  ${this.form.email}`)

          } catch (error) {
            console.info(`login: entrando catch erro  ${this.form.email}`)
            console.info(`login: error ${JSON.stringify(error)} - ${this.form.email}`)
            this.$store.dispatch('notificationErrors', error.response ? error.response.data.erros : response.data.erros);
            console.error("errr", error)

          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    },
    async facebookAuthenticate() {
      try {
        await window.FB.login(async resp => {
          if (resp.status === 'connected') {
            this.loginFacebookAuth(resp.authResponse.accessToken)
          } else {
            this.$store.dispatch('notification', { type: 'error', message: 'Falha ao fazer login com o Facebook.' })
          }
        }, { scope: 'email' })
      } catch (error) {
        console.log(error)
      }
    },
    async loginFacebookAuth(accessToken) {
      const response = (await Login.loginFacebook(accessToken)).data.data
      if (response.autenticacao === null && response.model) {
        this.$router.push({ path: '/investimento/solicitar', query: { model: response.model } })
      } else if (response.autenticacao) {
        await this.$store.dispatch('setLogout')
        await this.$store.dispatch('setLogin', response.autenticacao)
        this.$router.push('/investidor/painel')
      } else {
        this.$store.dispatch('notification', { type: 'error', message: 'Falha ao fazer login com o Facebook.' })
      }
    },
    async googleAuthenticate() {
      try {
        const google = await this.$gAuth.signIn()
        const accessToken = google.getAuthResponse().access_token
        if (accessToken) {
          const response = (await Login.loginGoogle(accessToken)).data.data
          if (response.autenticacao === null && response.model) {
            this.$router.push({ path: '/investimento/solicitar', query: { model: response.model } })
          } else if (response.autenticacao) {
            await this.$store.dispatch('setLogout')
            await this.$store.dispatch('setLogin', response.autenticacao)
            this.$router.push('/investidor/painel')
          } else {
            this.$store.dispatch('notification', { type: 'error', message: 'Falha ao fazer login com o Google.' })
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Falha ao fazer login com o Google.' })
        }
      } catch (error) {
        if (error.response.data.data) {
          const response = error.response.data.data
          this.$router.push({ path: '/investimento/solicitar', query: { model: response.model } })
        } else {
          console.log(error)
        }
      }
    }
  },
  async mounted() {
    if (this.$route.query.bearerToken) {
      try {
        var decoded = jwt_decode(this.$route.query.bearerToken);
        console.log(decoded);
        console.log(this.$store.state.email)
        if (decoded.sub != this.$store.state.email) {
          const response = (await LoginBearer.loginBearer({ bearerToken: this.$route.query.bearerToken })).data.data
          this.$store.dispatch('setLogout')
          this.$store.dispatch('setLogin', response)
          if (!response.roles.includes('investidor')) throw new Error('roleError')

          if (!this.$route.query.returnUrl) {
            this.$router.push('/investidor/painel')
          } else {
            this.$router.push(new URL(this.$route.query.returnUrl).pathname)
          }
        } else {
          if (!this.$route.query.returnUrl) {
            this.$router.push('/investidor/painel')
          } else {
            this.$router.push(new URL(this.$route.query.returnUrl).pathname)
          }
        }

      } catch (error) {
        this.$router.push('/investidor/login')
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.bf
  background-color: #333
  color: #eee
  padding: 10px
  @apply rounded text-center font-bold mb-4

.wrapper
  @apply w-screen h-full flex flex-col
  @screen lg
    @apply flex-row h-screen

.image
  @apply h-64 bg-cover bg-no-repeat
  background-image: url("../../assets/images/login/bg-login-investor-mobile.png")
  background-position: 0 15%
  @screen lg
    @apply w-1/4 h-full bg-center
    background-image: url("../../assets/images/login/bg-login-investor.png")

.article
  @apply hidden
  @screen lg
    @apply w-1/4 h-full bg-secondary flex items-center justify-center shadow

.article_content
  @apply mx-8 items-center flex flex-col text-center leading-relaxed

.title
  @apply text-white font-bold text-2xl mb-5

.subtitle
  @apply text-white font-bold text-lg

.text
  @apply text-white mb-5 text-sm w-2/3

.form_section
  @apply flex flex-col items-center justify-center
  @screen lg
    @apply w-1/2

.form_content
  @apply flex flex-col items-center
  @screen lg
    @apply my-auto

.logo
  @apply bg-no-repeat h-16 w-40 my-5 cursor-pointer
  background-image: url("../../assets/images/logo.svg")

.form_title
  @apply text-center mb-5 text-gray-700
  @screen lg
    @apply text-2xl

.pass_recovery
  @apply self-end text-sm text-gray-700 cursor-pointer
  &:hover, &:focus
    @apply text-secondary

.social_title
  @apply text-gray-700 mb-3 text-sm
  @screen lg
    @apply text-base mt-4

.social
  @apply flex justify-center

.social_facebook
  @apply bg-no-repeat w-8 h-8 mr-4 bg-contain cursor-pointer
  background-image: url("../../assets/images/login/facebook.svg")
  @screen lg
    @apply w-12 h-12

.social_google_plus
  @apply bg-no-repeat w-8 h-8 mr-4 bg-contain cursor-pointer
  background-image: url("../../assets/images/login/google.svg")
  @screen lg
    @apply w-12 h-12

.footer
  @apply text-center text-xss text-gray-700 leading-relaxed mt-8
  @screen lg
    @apply pb-4 text-xs mt-1

</style>
