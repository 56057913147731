import api from '@/plugins/axios'

const base = 'v1/'

export default {
  forgotPassword (data, req) {
    return api.post(`${base}${req}/solicitar-recuperacao-senha`, data)
  },
  changePassword (data, req) {
    return api.post(`${base}${req}/alterar-senha`, data)
  }
}
